<template>
  <div class="dx-viewport">
    <b-row v-if="items.length">
      <b-col md="12">
        <div class="form-group mt-3">
          <div class="checkbox-inline">
            <label class="checkbox checkbox-primary">
              <input type="checkbox" v-model="personalized" />
              <span></span>¿Quiere personalizar su reporte?</label
            >
          </div>
        </div>
      </b-col>
      <b-col md="12" v-if="!personalized">
        <DxDataGrid
          :ref="gridRefName"
          :data-source="items"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :columnAutoWidth="true"
          :allow-column-resizing="true"
          :allow-column-reordering="true"
          id="settlementTable"
        >
          <DxExport :enabled="true" />
          <DxColumnChooser
            emptyPanelText="Arrastre una columna aquí para ocultar"
            :enabled="true"
            title="Arrastre columna aquí..."
          />
          <DxColumnFixing :enabled="true" />
          <DxGroupPanel
            :visible="true"
            emptyPanelText="Arrastre una columna aquí para agrupar"
          />
          <DxSearchPanel :visible="true" :width="240" placeholder="Buscar..." />
          <DxPaging :page-size="30" />
          <DxPager
            :visible="true"
            infoText="Página {0} de {1} ({2} registros)"
            :allowed-page-sizes="[5, 15, 30, 50]"
            display-mode="full"
            :show-page-size-selector="true"
            :show-info="true"
            :show-navigation-buttons="true"
          />
          <DxHeaderFilter :visible="true" :allowSearch="true" />
          <DxFilterRow :visible="true" >
            <DxOperationDescriptions
              equal="Igual a"
              notEqual="Diferente de"
              startsWith="Empieza con"
              endsWith="Termina con"
              contains="Contiene"
              between="Entre"
              notContains="No contiene"
              lessThanOrEqual="Menor o igual"
              greaterThanOrEqual="Mayor o igual"
              lessThan="Menor que"
              greaterThan="Mayor que"
            />
          </DxFilterRow>
          <DxGroupPanel :visible="true" />
          <DxColumn caption="UCC" alignment="center" data-field="codigo_uc" />
          <DxColumn
            caption="Valor"
            alignment="center"
            data-field="valor"
            data-type="currency"
          />
          <DxColumn
            caption="Cantidad"
            alignment="center"
            data-field="cantidad_total"
          />
          <DxColumn
            caption="Total"
            alignment="center"
            data-field="total"
            data-type="currency"
          />

          <DxColumn
            data-field="cliente"
            caption="Contratista"
            :group-index="0"
          />

          <DxColumn
            data-field="fecha"
            caption="Fecha"
            alignment="center"
            data-type="date"
            format="yyyy/MM/dd"
            width="120"
            :group-index="1"
          />
          <DxSummary>
            <!-- <DxGroupItem
              column="cantidad_total"
              summary-type="sum"
              display-format="Total: {0}"
              :align-by-column="true"
            /> -->
            <DxTotalItem
              column="cantidad_total"
              summary-type="sum"
              display-format="Total: {0}"
            />
            <DxTotalItem
              column="total"
              summary-type="sum"
              value-format="currency"
              display-format="Total: {0}"
            />
          </DxSummary>
          <DxToolbar>
            <DxItem location="after" template="refreshTemplate" />
            <DxItem name="columnChooserButton" />
            <DxItem name="exportButton" />
            <DxItem name="groupPanel" />
            <DxItem name="searchPanel" />
          </DxToolbar>
          <template #refreshTemplate>
            <DxButton icon="refresh" @click="refreshDataGrid()" />
          </template>
        </DxDataGrid>
      </b-col>
      <transition name="slide-fade">
        <b-col md="12" v-if="personalized">
          <DxPivotGrid
            id="pivotgrid"
            ref="grid"
            :data-source="dataSource"
            :allow-sorting-by-summary="true"
            :allow-filtering="true"
            :show-borders="true"
            :show-column-grand-totals="true"
            :show-row-grand-totals="true"
            :show-row-totals="false"
            :show-column-totals="false"
            alinment="center"
            :texts="MainText"
          >
            <DxExport :enabled="true" />
            <DxFieldChooser
              title="Genera tu reporte"
              :layout="2"
              :texts="pivotText"
              :allowSearch="true"
              :enabled="true"
              :height="400"
            />
            <DxScrolling mode="standard" />
          </DxPivotGrid>
        </b-col>
      </transition>
    </b-row>
    <b-row v-else>
      <b-col>
        <div v-if="!loading">
          <div class="text-center">
            <img src="assets/img/empty.png" alt="Empty" />
            <p class="text-muted">No hay información para mostrar</p>
          </div>
        </div>
        <div class="text-center" v-else>
          <img src="assets/img/loader.gif" alt="Loading..." />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<style>
#settlementTable {
  max-height: 700px !important;
}
</style>
<script>
import {
  DxDataGrid,
  DxPaging,
  DxSearchPanel,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxOperationDescriptions,
  DxExport,
  DxGroupPanel,
  DxColumn,
  DxColumnChooser,
  DxColumnFixing,
  DxToolbar,
  DxItem,
  DxSummary,
  DxTotalItem,
  // DxGroupItem
} from "devextreme-vue/data-grid";
import {
  DxPivotGrid,
  DxFieldChooser,
  DxScrolling,
} from "devextreme-vue/pivot-grid";
import { DxButton } from "devextreme-vue/button";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import axios from "axios";

export default {
  components: {
    DxPivotGrid,
    DxFieldChooser,
    DxScrolling,
    DxDataGrid,
    DxPaging,
    DxSearchPanel,
    DxOperationDescriptions,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
    DxExport,
    DxGroupPanel,
    DxColumn,
    DxColumnChooser,
    DxColumnFixing,
    DxButton,
    DxToolbar,
    DxItem,
    DxSummary,
    DxTotalItem,
    // DxGroupItem
  },
  watch: {
    // personalized() {
    //   if (!this.personalized) {
    //     this.dataSource = new PivotGridDataSource({});
    //   }
    // },
  },
  data() {
    return {
      gridRefName: "dataGrid",
      loading: false,
      personalized: false,
      items: [],
      pageSizes: [5, 10, 15, 30, 50],
      MainText: {
        noData: "No hay información",
        grandTotal: "Total",
        collapseAll: "Desplegar todo",
        expandAll: "Expandir todo",
        exportToExcel: "Exportar a Excel",
        removeAllSorting: "Quitar orden",
        showFieldChooser: "Mostrar selector de campo",
        sortColumnBySummary: "Ordenar {0} por esta columna",
        sortRowBySummary: "Ordenar {0} por esta fila"
      },
      pivotText: {
        allFields: "Todos los campos",
        columnFields: "Columnas",
        dataFields: "Valores",
        filterFields: "Campos de filtro",
        rowFields: "Filas",
      },
      texts: {
        editRow: "Editar",
        saveRowChanges: "Guardar",
        saveAllChanges: "Guardar cambios",
        deleteRow: "Eliminar",
        cancelRowChanges: "Cancelar",
        confirmDeleteTitle: "Información",
        confirmDeleteMessage: "¿Realmente desea eliminar el registro?",
        cancelAllChanges: "Descartar cambios",
      },
      dataSource: [],
    };
  },
  mounted() {
    this.loadSettlement();
  },
  methods: {
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Information");

      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            `${this.$route.name}.xlsx`
          );
        });
      });
      e.cancel = true;
    },
    loadSettlement() {
      this.loading = true;
      this.items = [];
      axios
        .get(`/turns-liquidations-consolidado`)
        .then((res) => {
          this.loading = false;
          // const options2 = { style: "currency", currency: "COP" };
          // const numberFormat2 = new Intl.NumberFormat("es-CO", options2);

          res.data.map((el) => {
            // el.total = numberFormat2.format(
            //   parseFloat(el.cantidad_total) * parseFloat(el.valor)
            // );
            el.cantidad_total = parseFloat(el.cantidad_total);
            el.valor = parseFloat(el.valor);
            el.total = parseFloat(el.cantidad_total) * parseFloat(el.valor);
          });
          this.items = res.data;
          this.dataSource = new PivotGridDataSource({
            fields: [
              {
                caption: "Cantidad total",
                dataField: "cantidad_total",
                dataType: "number",
                summaryType: "sum",
                alignment: "center",
              },
              {
                caption: "Contratista",
                dataField: "cliente",
              },
              {
                caption: "Código UCC",
                dataField: "codigo_uc",
              },
              {
                caption: "Fecha",
                dataField: "fecha",
              },
              {
                caption: "Valor",
                dataField: "valor",
                dataType: "number",
                format: "currency",
                summaryType: "sum",
                alignment: "center",
              },
              {
                caption: "Total",
                dataField: "total",
                dataType: "number",
                format: "currency",
                summaryType: "sum",
              },
            ],
            store: this.items,
          });
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    refreshDataGrid() {
      this.loadSettlement();
    },
  },
};
</script>
