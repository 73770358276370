<template>
  <b-card title="Liquidación">
    <b-row class="mt-3">
      <b-col>
        <b-tabs small v-model="tab" id="settlement-tabs" lazy>
          <b-tab title="Cumplimiento turnos">
            <transition name="slide-fade">
              <ShiftFulfillment v-if="tab == 0" />
            </transition>
          </b-tab>
          <b-tab title="Liquidación por brigada" lazy>
            <SettlementTech v-if="tab == 1" />
          </b-tab>
          <b-tab title="Consolidado" lazy>
            <SettlementConsolidated v-if="tab == 2" />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-card>
</template>
<style>
#settlement-tabs > .tab-content {
  overflow: hidden !important;
  height: 100vh !important;
}
</style>
<script>
import { mapActions, mapGetters } from "vuex";
import SettlementConsolidated from "./SettlementConsolidated.vue";
import SettlementTech from "./SettlementTech.vue";
import ShiftFulfillment from "./ShiftFulfillment.vue";
export default {
  data() {
    return {
      tab: 0,
    };
  },
  components: {
    ShiftFulfillment,
    SettlementTech,
    SettlementConsolidated,
  },
  beforeMount() {
    this.tab = this.tabIndex;
  },
  computed: {
    ...mapGetters({
      tabIndex: "SettLement/getTab",
    }),
  },
  watch: {
    tab() {
      this.setTab(this.tab);
    },
  },
  methods: {
    ...mapActions({
      setTab: "SettLement/setTab",
    }),
  },
};
</script>
