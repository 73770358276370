<template>
  <div class="dx-viewport">
    <b-row v-if="items.length">
      <b-col md="12">
        <div class="form-group mt-3">
          <div class="checkbox-inline">
            <label class="checkbox checkbox-primary">
              <input type="checkbox" v-model="personalized" />
              <span></span>¿Quiere personalizar su reporte?</label
            >
          </div>
        </div>
      </b-col>
      <b-col v-if="!personalized">
        <DxDataGrid
          :ref="gridRefName"
          :data-source="items"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :columnAutoWidth="true"
          :allow-column-resizing="true"
          :allow-column-reordering="true"
          id="settlementTable"
        >
          <DxExport :enabled="true" />
          <DxColumnChooser
            emptyPanelText="Arrastre una columna aquí para ocultar"
            :enabled="true"
            title="Arrastre columna aquí..."
          />
          <DxColumnFixing :enabled="true" />
          <DxGroupPanel
            :visible="true"
            emptyPanelText="Arrastre una columna aquí para agrupar"
          />
          <DxSearchPanel :visible="true" :width="240" placeholder="Buscar..." />
          <DxPaging :page-size="30" />
          <DxPager
            :visible="true"
            infoText="Página {0} de {1} ({2} registros)"
            :allowed-page-sizes="[5, 15, 30, 50]"
            display-mode="full"
            :show-page-size-selector="true"
            :show-info="true"
            :show-navigation-buttons="true"
          />
          <DxHeaderFilter :visible="true" :allowSearch="true"/>
          <DxFilterRow :visible="true">
            <DxOperationDescriptions
              equal="Igual a"
              notEqual="Diferente de"
              startsWith="Empieza con"
              endsWith="Termina con"
              contains="Contiene"
              between="Entre"
              notContains="No contiene"
              lessThanOrEqual="Menor o igual"
              greaterThanOrEqual="Mayor o igual"
              lessThan="Menor que"
              greaterThan="Mayor que"
            />
          </DxFilterRow>
          <DxGroupPanel :visible="true" />
          <DxColumn caption="UCC" alignment="center" data-field="codigo_uc" />
          <DxColumn
            caption="Valor"
            alignment="center"
            data-field="valor"
            data-type="currency"
          />

          <DxColumn
            data-field="cliente"
            caption="Contratista"
            :group-index="0"
          />
          <DxColumn
            data-field="brigada"
            caption="Cód. brigada"
            width="130"
            alignment="center"
          />
          <DxColumn
            data-field="nombre"
            caption="Tecnico"
            width="130"
            alignment="center"
          />

          <DxColumn data-field="placa" caption="Vehículo" alignment="center" />
          <DxColumn
            data-field="porcentaje_horas"
            caption="Porcentaje"
            alignment="center"
            width="120"
          />
          <DxColumn
            data-field="fecha"
            caption="Fecha"
            alignment="center"
            data-type="date"
            format="yyyy/MM/dd"
            width="120"
            :group-index="1"
          />
          <DxToolbar>
            <DxItem location="after" template="refreshTemplate" />
            <DxItem name="columnChooserButton" />
            <DxItem name="exportButton" />
            <DxItem name="groupPanel" />
            <DxItem name="searchPanel" />
          </DxToolbar>
          <template #refreshTemplate>
            <DxButton icon="refresh" @click="refreshDataGrid()" />
          </template>
        </DxDataGrid>
      </b-col>
      <b-col v-if="personalized">
        <DxPivotGrid
          id="pivotgrid"
          ref="grid"
          :data-source="items"
          :allow-sorting-by-summary="true"
          :allow-filtering="true"
          :show-borders="true"
          :show-column-grand-totals="false"
          :show-row-grand-totals="false"
          :show-row-totals="false"
          :show-column-totals="false"
          alinment="center"
          :texts="MainText"
        >
          <DxExport :enabled="true" />
          <DxFieldChooser
            title="Genera tu reporte"
            :layout="2"
            :texts="pivotText"
            :allowSearch="true"
            :enabled="true"
            :height="400"
          />
          <DxScrolling mode="virtual" />
        </DxPivotGrid>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col>
        <div v-if="!loading">
          <div class="text-center">
            <img src="assets/img/empty.png" alt="Empty" />
            <p class="text-muted">No hay información para mostrar</p>
          </div>
        </div>
        <div class="text-center" v-else>
          <img src="assets/img/loader.gif" alt="Loading..." />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<style>
#settlementTable {
  max-height: 700px !important;
}
</style>
<script>
import {
  DxDataGrid,
  DxPaging,
  DxSearchPanel,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxOperationDescriptions,
  DxExport,
  DxGroupPanel,
  DxColumn,
  DxColumnChooser,
  DxColumnFixing,
  DxToolbar,
  DxItem,
} from "devextreme-vue/data-grid";
import {
  DxPivotGrid,
  DxFieldChooser,
  DxScrolling,
} from "devextreme-vue/pivot-grid";
import { DxButton } from "devextreme-vue/button";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import axios from "axios";
export default {
  components: {
    DxPivotGrid,
    DxFieldChooser,
    DxScrolling,
    DxDataGrid,
    DxPaging,
    DxSearchPanel,
    DxOperationDescriptions,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
    DxExport,
    DxGroupPanel,
    DxColumn,
    DxColumnChooser,
    DxColumnFixing,
    DxButton,
    DxToolbar,
    DxItem,
  },
  data() {
    return {
      personalized: false,
      gridRefName: "dataGrid",
      loading: false,
      items: [],
      pageSizes: [5, 10, 15, 30, 50],
      pivotText: {
        allFields: "Todos los campos",
        columnFields: "Columnas",
        dataFields: "Valores",
        filterFields: "Campos de filtro",
        rowFields: "Filas",
      },
      MainText: {
        noData: "No hay información",
        grandTotal: "Total",
        collapseAll: "Desplegar todo",
        expandAll: "Expandir todo",
        exportToExcel: "Exportar a Excel",
        removeAllSorting: "Quitar orden",
        showFieldChooser: "Mostrar selector de campo",
        sortColumnBySummary: "Ordenar {0} por esta columna",
        sortRowBySummary: "Ordenar {0} por esta fila",
      },
      texts: {
        editRow: "Editar",
        saveRowChanges: "Guardar",
        saveAllChanges: "Guardar cambios",
        deleteRow: "Eliminar",
        cancelRowChanges: "Cancelar",
        confirmDeleteTitle: "Información",
        confirmDeleteMessage: "¿Realmente desea eliminar el registro?",
        cancelAllChanges: "Descartar cambios",
      },
      dataSource: [],
    };
  },
  mounted() {
    this.loadSettlement();
  },

  watch: {
    personalized() {
      if (!this.personalized) {
        this.dataSource = new PivotGridDataSource({});
      }
    },
  },
  methods: {
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Information");

      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            `${this.$route.name}.xlsx`
          );
        });
      });
      e.cancel = true;
    },
    loadSettlement() {
      this.loading = true;
      this.items = [];
      axios
        .get(`/turns-liquidations`)
        .then((res) => {
          this.items = res.data;
          this.loading = false;
          res.data.map((el) => {
            if (el.turno) {
              el.turno.observacion = el.turno.observacion
                .split("-")
                .join(" y ")
                .split("|")
                .join(" a ");
            }
          });
          this.dataSource = new PivotGridDataSource({
            fields: [
              {
                caption: "Cód Brigada",
                dataField: "brigada",
              },
              {
                caption: "Tecnico",
                dataField: "nombre",
              },
              {
                caption: "Contrata",
                dataField: "cliente",
              },
              {
                caption: "Código UCC",
                dataField: "codigo_uc",
              },
              {
                caption: "Fecha",
                dataField: "fecha",
              },
              {
                caption: "Placa - Recurso",
                dataField: "placa",
              },
              {
                caption: "Porcentaje",
                dataField: "porcentaje_horas",
              },
              {
                caption: "Valor",
                dataField: "valor",
                dataType: "number",
              },
            ],
            store: this.items,
          });
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    refreshDataGrid() {
      this.loadSettlement();
    },
  },
};
</script>
